<template>
  <v-container fluid>
    <relatorios-pre-view :relatorioId="this.$route.params.relatorioId" />
  </v-container>
</template>

<script>
export default {
  components: {
    RelatoriosPreView: () => import('@/components/relatorios/RelatoriosPreView')
  }
};
</script>
